export const Carousel = el => {
  const ui = {
    el,
    carousel: el.querySelector('.carousel'),
    elPrev: el.querySelector('[data-js-left-arrow]'),
    elNext: el.querySelector('[data-js-right-arrow]'),
    pagination: el.querySelector('[data-js-pagination-dots]'),
    slides: el.querySelectorAll('.carouselSlide'),
    automatic: el.querySelector('.automatic-slide'),
    pause: el.querySelector('.slide-interval')
  };
  const mod = (number, divider) => (number % divider + divider) % divider; // Helper Modulo Function
  const totalSlides = ui.slides.length;
  const animation = 500; // Animation time
  let pause = ui.pause.textContent ? parseInt(ui.pause.textContent) : 5000; // Pause Time on each slide
  const elCarouselSlider = ui.carousel;
  const elsSlides = ui.slides;
  let dist; // Finger Swiping variable
  let currentX; // Finger Swiping variable
  let startX; // Finger Swiping variable
  let interval; // Autoslide interval
  let totSlides = elsSlides.length; // Total slides
  let currentIndex = 0;
  if (totSlides < 2) return; // Not enough slides. Do nothing.

  // Methods and Functions:
  const anim = (ms = animation) => {
    const cMod = mod(currentIndex, totSlides); // Gets the slide to go to

    // Move slider
    elCarouselSlider.style.transitionDuration = `${ms}ms`;
    elCarouselSlider.style.transform = `translateX(${(-currentIndex - 1) * 100}%)`;

    // Handle active classes (slide and bullet)
    elsSlides.forEach((elSlide, i) => elSlide.classList.toggle('is-active', cMod === i));
    determineAriasTabs();
    updatePagination();
  };
  const prev = () => {
    if (currentIndex <= -1) return; // prevent blanks on fast prev-click
    currentIndex -= 1;
    anim();
  };
  const next = () => {
    if (currentIndex >= totSlides) return; // prevent blanks on fast next-click
    currentIndex += 1;
    anim();
  };
  const goto = index => {
    currentIndex = index;
    anim();
  };

  // Function to create pagination dots
  const createPaginationDots = () => {
    if (totalSlides == 1) {
      ui.pagination.setAttribute('aria-hidden', 'true');
      ui.leftArrow.classList.add('hidden');
      ui.rightArrow.classList.add('hidden');
    } else {
      ui.pagination.setAttribute('aria-hidden', 'false');
      for (let i = 0; i < totalSlides; i++) {
        const dot = document.createElement('li');
        dot.classList.add('dot');
        dot.setAttribute('data-index', i);
        dot.setAttribute('role', 'button');
        dot.setAttribute('tabindex', '0');
        dot.setAttribute('aria-label', 'Click to move to slide ' + i);
        ui.pagination.appendChild(dot);
      }
    }
    updatePagination(); // Update pagination dots initially
  };

  // Adds Aria-hidden labels to slides
  const determineAriasTabs = () => {
    for (let i = 0; i < totalSlides; i++) {
      if (i === currentIndex) {
        ui.slides[i].setAttribute('aria-hidden', 'false');
        const links = ui.slides[i].querySelectorAll('a');
        links.forEach(link => {
          link.setAttribute('tabindex', '0');
        });
      } else {
        ui.slides[i].setAttribute('aria-hidden', 'true');
        const links = ui.slides[i].querySelectorAll('a');
        links.forEach(link => {
          link.setAttribute('tabindex', '-1');
        });
      }
    }
  };

  // Function to update active pagination dot
  const updatePagination = () => {
    const dots = el.querySelectorAll('.dot');
    dots.forEach((dot, index) => {
      if (index === currentIndex) {
        dot.classList.add('active');
      } else {
        dot.classList.remove('active');
      }
    });
  };

  // Clickable Pagination Dots
  ui.pagination.addEventListener('click', e => {
    if (e.target.classList.contains('dot')) {
      const dotIndex = parseInt(e.target.getAttribute('data-index'));
      console.log(dotIndex);
      if (dotIndex !== currentIndex) {
        goto(dotIndex);
      }
    }
  });

  // Buttons:
  ui.elPrev.addEventListener('click', () => {
    if (!ui.elPrev.disabled) {
      prev();
    }
  });
  ui.elNext.addEventListener('click', () => {
    if (!ui.elNext.disabled) {
      next();
    }
  });

  // Start autoplay
  if (ui.automatic && ui.automatic.textContent === '1') {
    const play = () => {
      interval = setInterval(next, pause + animation);
    };
    const stop = () => {
      clearInterval(interval);
    };
    play();

    // Pause on pointer enter:
    ui.carousel.addEventListener('pointerenter', () => stop());
    ui.carousel.addEventListener('pointerleave', () => play());
  }

  // Finger Swiping Methods:
  const handleSwipeStart = e => {
    startX = e.touches[0].clientX;
  };
  const handleSwipeMove = e => {
    if (startX) {
      currentX = e.touches[0].clientX;
      dist = currentX - startX;
    }
  };
  const handleSwipeEnd = () => {
    if (Math.abs(dist) > 50) {
      if (dist > 0) {
        goto(currentIndex - 1);
      } else {
        goto(currentIndex + 1);
      }
    }
    startX = 0;
    dist = 0;
  };

  // Infinite slide effect:
  elCarouselSlider.addEventListener('transitionend', () => {
    if (currentIndex <= -1) currentIndex = totSlides - 1;
    if (currentIndex >= totSlides) currentIndex = 0;
    anim(0); // quickly switch to "c" slide (with animation duration 0)
  });

  // Clone first and last slides (for "infinite" slider effect)
  elCarouselSlider.prepend(elsSlides[totSlides - 1].cloneNode(true));
  elCarouselSlider.append(elsSlides[0].cloneNode(true));

  // Initial slide and Pagination dot creation
  createPaginationDots();
  anim(0);

  // Add Finger Swiping methods
  ui.carousel.addEventListener('touchstart', handleSwipeStart);
  ui.carousel.addEventListener('touchmove', handleSwipeMove);
  ui.carousel.addEventListener('touchend', handleSwipeEnd);
};
export default Carousel;