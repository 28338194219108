export const CampusMap = el => {
  let scale = 1;
  let translateX = 0;
  let translateY = 0;
  let isDragging = false;
  let startX, startY;
  const imageContainer = el.querySelector('#image-container');
  const image = el.querySelector('#zoomable-image');
  const zoomInBtn = document.querySelector('#zoom-in'); // Updated to target the zoom buttons outside the container
  const zoomOutBtn = document.querySelector('#zoom-out'); // Updated to target the zoom buttons outside the container

  function zoomIn() {
    scale += 0.5;
    updateTransform();
  }
  function zoomOut() {
    if (scale > 1.0) {
      scale -= 0.5;
      if (scale < 1.0) scale = 1.0; // Ensure scale does not go below 1.0
      updateTransform();
    }
  }
  zoomInBtn.addEventListener('click', e => {
    e.stopPropagation(); // Prevent the click event from propagating
    zoomIn();
  });
  zoomOutBtn.addEventListener('click', e => {
    e.stopPropagation(); // Prevent the click event from propagating
    zoomOut();
  });
  function updateTransform() {
    image.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
  }
  function getBoundaries() {
    const containerRect = imageContainer.getBoundingClientRect();
    const imageRect = image.getBoundingClientRect();
    const maxX = (imageRect.width - containerRect.width) / 2 / scale;
    const maxY = (imageRect.height - containerRect.height) / 2 / scale;
    return {
      minX: -maxX,
      maxX: maxX,
      minY: -maxY,
      maxY: maxY
    };
  }
  function startDrag(x, y) {
    isDragging = true;
    startX = x;
    startY = y;
    imageContainer.style.cursor = 'grabbing';
  }
  function dragMove(x, y) {
    if (isDragging) {
      const dx = x - startX;
      const dy = y - startY;
      translateX += dx / scale;
      translateY += dy / scale;
      const boundaries = getBoundaries();
      translateX = Math.min(boundaries.maxX, Math.max(boundaries.minX, translateX));
      translateY = Math.min(boundaries.maxY, Math.max(boundaries.minY, translateY));
      startX = x;
      startY = y;
      updateTransform();
    }
  }
  function endDrag() {
    isDragging = false;
    imageContainer.style.cursor = 'grab';
  }

  // Mouse events
  imageContainer.addEventListener('mousedown', e => {
    startDrag(e.clientX, e.clientY);
    e.preventDefault(); // Prevent default drag behavior
  });
  el.addEventListener('mousemove', e => {
    dragMove(e.clientX, e.clientY);
  });
  el.addEventListener('mouseup', endDrag);
  imageContainer.addEventListener('mouseleave', endDrag);

  // Touch events
  imageContainer.addEventListener('touchstart', e => {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      startDrag(touch.clientX, touch.clientY);
    }
    e.preventDefault(); // Prevent default behavior
  });
  el.addEventListener('touchmove', e => {
    if (e.touches.length === 1) {
      const touch = e.touches[0];
      dragMove(touch.clientX, touch.clientY);
    }
    e.preventDefault(); // Prevent default behavior
  });
  el.addEventListener('touchend', endDrag);
  imageContainer.addEventListener('touchcancel', endDrag);

  // Disable default drag behavior on the image
  image.addEventListener('dragstart', e => {
    e.preventDefault();
  });
};
export default CampusMap;