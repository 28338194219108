/**
 * Pass in the Bio fields JSON and the modal EL to populate the Modal with Bio fields
 * @param  Object 		data    JSON object of ACF fields for this BIO (including added image HTML)
 * @param  Element 		modalEl The MicroModal El/Object to repopulate with the ACF Bio fields
 * @return null/string         	Returns nothing, but populates the Modal with HTML string.
 */
export const populateBioModal = (data, modalEl) => {
  const contentEl = modalEl.querySelector('[data-bio-modal-content]'),
    bio = JSON.parse(data);
  let bioContentHtml = '<div class="bio flex flex-col relative"><div>';
  bioContentHtml += `
	<div class="bio__content-wrapper rounded-xl md:text-lg flex-auto basis-full overflow-auto max-h-auto relative md:max-h-[75vh] md:overflow-y-auto md:overflow-x-hidden scrollbar-none md:scrollbar scrollbar-w-2 md:scrollbar-thumb-rounded md:scrollbar-track-rounded md:scrollbar-thumb-[#747474ae] md:scrollbar-track-[#e4e4e4dc]">
		<div class="h-auto">
			<div class="mx-auto w-full overflow-x-auto bg-[#f9f9f9]">
	  			<div class="flex h-auto flex-col md:flex-row">
					<div class="flex w-full md:w-1/2">
						<div class="flex flex-col justify-between mt-[20px] md:mt-2 pb-8 pt-2 md:pt-4 pl-[3.125vw] pr-[3.125vw] xl:px-[40px]">`;

  // Top Half Div
  if (bio.top_level_terms || bio.bios_department || bio.bios_first_name && bio.bios_last_name || bio.bios_title || bio.bios_slider_card_subtitle) {
    bioContentHtml += '<div>';
  }

  // Top Level Terms
  if (bio.top_level_terms) {
    for (let i = 0; i < bio.top_level_terms.length; i++) {
      bioContentHtml += `<span class="w-max rounded-md shadow-md py-2 px-2 mr-3 p5 font-light leading-loose">${bio.top_level_terms[i]}</span>`;
    }
  }

  // Top Div without "Top Level Terms"
  if (bio.bios_department || bio.bios_first_name && bio.bios_last_name || bio.bios_title || bio.bios_slider_card_subtitle) {
    bioContentHtml += '<div class="mt-10">';
  }

  // Department
  if (bio.bios_department) {
    bioContentHtml += `<h6 class="text-red-bright">${bio.bios_department}</h6>`;
  }

  // Name
  if (bio.bios_first_name && bio.bios_last_name) {
    bioContentHtml += `<h1 class="h3-alt xl:h5 xl:font-heading mb-1">${bio.bios_first_name + ' ' + bio.bios_last_name}</h1>`;
  }

  // Title
  if (bio.bios_title) {
    bioContentHtml += `<p class="p2">${bio.bios_title}</p>`;
  }

  // Subtitle
  if (bio.bios_slider_card_subtitle) {
    bioContentHtml += `<div class="bio__card-sub-title" style="margin-top: -1em;"><p class="p2">${bio.bios_slider_card_subtitle}</p></div>`;
  }

  // Closing Top Div without "Top Level Terms"
  if (bio.bios_department || bio.bios_first_name && bio.bios_last_name || bio.bios_title || bio.bios_slider_card_subtitle) {
    bioContentHtml += '</div>';
  }

  // Closing Top Half Div
  if (bio.top_level_terms || bio.bios_department || bio.bios_first_name && bio.bios_last_name || bio.bios_title || bio.bios_slider_card_subtitle) {
    bioContentHtml += '</div>';
  }

  // Bottom Half Div
  if (bio.bios_location || bio.bios_urls || bio.bios_email || bio.bios_phone) {
    bioContentHtml += '<div>';
  }

  // Email
  if (bio.bios_email) {
    bioContentHtml += `<div class="bio__email"><p class="pt-1 p2 text-gray-700">Email: <a class="defaultLink" href="mailto:${bio.bios_email}" target="_blank"><span class="sr-only">New Window for email</span>${bio.bios_email}</a></p></div>`;
  }

  // Phone
  if (bio.bios_phone) {
    bioContentHtml += `<div class="bio__phone" style="margin-top: -1em;"><p class="p2 text-gray-700">Phone: <a class="defaultLink" href="tel:${bio.bios_phone}" target="_blank"><span class="sr-only">New Window for phone</span>${bio.bios_phone}</a></p></div>`;
  }

  // Location
  if (bio.bios_location) {
    bioContentHtml += `<div style="margin-top: -1em;"><p class="p2 inline-block mb-0 mr-2">Location:</p><span class="p2">${bio.bios_location}</span></li></div>`;
  }

  // URLS
  if (bio.bios_urls) {
    bioContentHtml += '<div class="bio__urls"><ul class="list-none mt-4 mb-0">';
    for (var i = 0; i < bio.bios_urls.length; i++) {
      bio.bios_urls[i];
      bioContentHtml += `<li class="mt-1"><a class="defaultLink" href="${bio.bios_urls[i].bios_urls_url}"${bio.bios_urls[i].bios_urls_url_new_tab ? ' target="_blank"' : ''}>${bio.bios_urls[i].bios_urls_url_title ? bio.bios_urls[i].bios_urls_url_title : bio.bios_urls[i].bios_urls_url}</a></li>`;
    }
    bioContentHtml += '</ul></div>';
  }

  // Closing Bottom Half Div
  if (bio.bios_location || bio.bios_urls || bio.bios_email || bio.bios_phone) {
    bioContentHtml += '</div>';
  }

  // Image
  bioContentHtml += `
		  	</div>
		</div>
		<div class="flex-auto basis-full md:basis-1/2 relative">
			<div class="modal__image flex w-full h-full max-h-[1000px]">${bio.bios_image_html}</div>`;

  // Biography
  bioContentHtml += `
				</div>
			</div>
	  	</div>`;
  if (bio.bios_state || bio.bios_grad_year || bio.bios_anticipated_grad_year || bio.bios_biography) {
    bioContentHtml += '<div class="flex flex-col h-full px-[6.25vw] xl:px-[80px] pt-8"> <h5>Biography</h5>';

    // State (Student/Alumni)
    if (bio.bios_state) {
      bioContentHtml += `<div class="bio__state"><span>${bio.bios_state}</span></div>`;
    }

    // Grad Year (alumni)
    if (bio.bios_grad_year) {
      bioContentHtml += `<div class="bio__anticipated-grad-year"><span>${bio.bios_grad_year}</span></div>`;
    }

    // Anticipated Grad Yead (Student)
    if (bio.bios_anticipated_grad_year) {
      bioContentHtml += `<div class="bio__anticipated-grad-year"><h6 class="p inline-block m-0 mr-2">Anticipated Graduation:</h6><span>${bio.bios_anticipated_grad_year}</span></div>`;
    }
    if (bio.bios_biography) {
      bioContentHtml += `
			<div class="bio__biography py-8 ">
			<div>${bio.bios_biography}</div>
		`;
    }
    bioContentHtml += '</div>';
  }

  // Quote
  if (bio.bios_quote) {
    bioContentHtml += `
		<div class="bio__quote bg-gray-gallery pt-8 pb-4 px-[6.25vw] xl:px-[80px]">
			<p class="small-quote">&ldquo;${bio.bios_quote}&rdquo;</p>
		</div>`;
  }

  // Content
  if (bio.bios_content) {
    bioContentHtml += '<section class="bio__content flex flex-col h-full pt-4 mt-[1.125em]">';
    for (var i = 0; i < bio.bios_content.length; i++) {
      bioContentHtml += '<div class="border-b border-gray-mercury px-[6.25vw] xl:px-[80px] pb-4 mb-8">';
      if (bio.bios_content[i].bios_content_heading) {
        bioContentHtml += `<h5>${bio.bios_content[i].bios_content_heading}</h5>`;
      }
      bioContentHtml += `<div class="p2"><p class="leading-normal">${bio.bios_content[i].bios_content_content}</p></div>`;
      bioContentHtml += '</div>';
    }
    bioContentHtml += '</section>';
  }
  bioContentHtml += `
			</div>
  	`;

  // End containers
  bioContentHtml += '</div></div>';

  // Empty the current Bio HTML
  contentEl.innerHTML = '';

  // Populate with new Bio Content
  contentEl.innerHTML = bioContentHtml;
};
export default populateBioModal;