export const StaticScroll = static_scroll_container => {
  const static_scroll = static_scroll_container.querySelector('ol');
  static_scroll.addEventListener('scroll', event => {
    const transition_offset = 40; // pixels to offset the transition by
    const scale_maximum = 1.05; // sets the maximum zoom size

    const image_container = static_scroll_container.querySelector('[data-js-static-scroll-image-container]');
    const interior_offsets = (() => {
      // Obtains the locations for each transition of each <li>
      const offsets = [];
      for (const li of static_scroll.children) {
        offsets.push(li.offsetTop);
      }
      return offsets;
    })();

    // Finds the index of the active image
    let active_image_index = 0;
    for (const offset of interior_offsets) {
      if (static_scroll.scrollTop > offset) {
        active_image_index = interior_offsets.indexOf(offset);
      }
    }

    // set the correct styling of each image
    Array.from(image_container.children).forEach((element, index) => {
      const transition_percentage = 1 - (interior_offsets[active_image_index + 1] - static_scroll.scrollTop) / transition_offset;
      switch (index) {
        case active_image_index + 1:
          element.classList.remove('hidden');
          element.style.opacity = '0';
          if (static_scroll.scrollTop > interior_offsets[active_image_index + 1] - transition_offset) {
            element.style.opacity = `${transition_percentage}`;
          }
          break;
        case active_image_index:
          element.classList.remove('hidden');
          element.style.opacity = '1';
          if (static_scroll.scrollTop > interior_offsets[active_image_index + 1] - transition_offset) {
            element.style.transform = `scale(${1 + transition_percentage * (scale_maximum - 1)})`;
          }
          break;
        default:
          element.classList.add('hidden');
          break;
      }
    });
  });
};
export default StaticScroll;