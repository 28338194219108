// Import Swiper and module styles
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import MicroModal from 'micromodal';
import populateBioModal from '@k-bits/bios/bio-modals';
export const BioSlider = el => {
  const ui = {
    el,
    swiperEl: el.querySelector('[data-js-bio-swiper]'),
    nextBtn: el.querySelector('[data-js-bio-next]'),
    prevBtn: el.querySelector('[data-js-bio-prev]'),
    modalBtns: el.querySelectorAll('[data-js-bio-modal]')
  };
  const bioSwiper = new Swiper(ui.swiperEl, {
    init: true,
    allowTouchMove: true,
    spaceBetween: 20,
    slidesPerView: 1,
    effect: 'slide',
    speed: 300,
    loop: true,
    initialSlide: 1,
    loopPreventsSliding: true,
    lazyLoadingInPrevNext: true,
    navigation: {
      nextEl: ui.nextBtn,
      prevEl: ui.prevBtn
    },
    breakpoints: {
      // when window width is >= 380px
      380: {
        slidesPerView: 2
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 4,
        spaceBetween: 32
      }
    },
    modules: [Navigation]
  });

  /**
   * For each Bio Card button, initiate MicroModal (required MicroModal and populateBioModal )
   */
  for (var i = ui.modalBtns.length - 1; i >= 0; i--) {
    ui.modalBtns[i].addEventListener('click', e => {
      const modalData = e.currentTarget.getAttribute('data-all-bio-fields');
      initModal(modalData);
    });
    ui.modalBtns[i].addEventListener('keydown', e => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        const modalData = e.currentTarget.getAttribute('data-all-bio-fields');
        initModal(modalData);
      }
    });
  }
  function initModal(modalData) {
    // Micromodal for each Bio;
    MicroModal.show('bio-slider-modal', {
      onShow: modal => populateBioModal(modalData, modal),
      openTrigger: 'data-bio-slider-modal-trigger',
      closeTrigger: 'data-bio-slider-modal-close',
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true
    });
  }
  function setAriaHiddenAttributes() {
    bioSwiper.slides.forEach((slide, index) => {
      const isSlideInView = index >= bioSwiper.activeIndex && index < bioSwiper.activeIndex + bioSwiper.params.slidesPerView;
      slide.setAttribute('aria-hidden', !isSlideInView);
      const clickableCard = slide.querySelector('.bio-card');
      if (isSlideInView) {
        clickableCard.setAttribute('tabindex', '0');
      } else {
        clickableCard.setAttribute('tabindex', '-1');
      }
    });
  }
  setAriaHiddenAttributes();
  bioSwiper.on('slideChange', () => {
    setAriaHiddenAttributes();
  });
};
export default BioSlider;