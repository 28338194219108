import { debounce } from 'lodash';
export const CardGrid = el => {
  const ui = {
    el,
    parentDivs: document.querySelectorAll('.card-grid__card')
  };
  document.addEventListener('DOMContentLoaded', function () {
    ui.el.addEventListener('focusin', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = '2px solid #219EBC';
        }
      }
    });
    ui.el.addEventListener('focusout', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = 'none';
        }
      }
    });
    ui.el.addEventListener('mouseover', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = '2px solid #9E1B32';
        }
      }
    });
    ui.el.addEventListener('mouseout', function (event) {
      if (event.target.matches('a.card-is-link')) {
        const parentDiv = event.target.closest('.card-grid__card');
        if (parentDiv) {
          parentDiv.style.outline = 'none';
        }
      }
    });
  });

  /**
   * For each Card, if an AOS anchor data attribute is not present, reset the animation delays
   * to accommodate the onscroll animations for each row of cards, adjusting the delay count
   * every 2, 3, or 4 iterations depending on the number of columns in the responsive layout.
   */
  if (ui.parentDivs.length > 0 && ui.parentDivs[0].getAttribute('data-aos-delay') && !ui.parentDivs[0].getAttribute('data-aos-anchor')) {
    const delayMS = ui.parentDivs[1] !== undefined ? ui.parentDivs[1].getAttribute('data-aos-delay') : 0;
    document.addEventListener('DOMContentLoaded', debounce(resetAOSdelays, 100));
    window.addEventListener('resize', debounce(resetAOSdelays, 100));
    function resetAOSdelays() {
      let mqMobile = window.matchMedia('(max-width: 474px)').matches;
      let mqTablet = window.matchMedia('(max-width: 1023px)').matches;
      let mqDesktop = window.matchMedia('(min-width: 1024px)').matches;
      let counter = 0;
      for (let i = 0; i < ui.parentDivs.length; i++) {
        // Check which media query matches and run a remainder check
        // against the # of columns for that media query
        // to reset the next row's delay sequence starting at 0
        if (mqMobile && i % 1 == 0) {
          counter = 0;
        } else if (mqTablet && i % 2 == 0) {
          counter = 0;
        } else if (mqDesktop && i % 4 == 0) {
          counter = 0;
        }

        // Update the data-aos-delay attribute with the updated delayMS
        ui.parentDivs[i].setAttribute('data-aos-delay', delayMS * counter);
        counter++;
      }
    }
  }
};
export default CardGrid;