import { debounce, last, throttle } from 'lodash';
const $ = jQuery;
export const Header = headerEl => {
  const ui = {
    headerEl,
    body: document.querySelector('body'),
    content: document.querySelector('.content'),
    primaryNavToggle: headerEl.querySelector('[data-primary-nav-toggle]'),
    primaryNav: headerEl.querySelector('[data-primary-nav]'),
    primaryNavUl: headerEl.querySelector('ul.header__primary-nav-ul'),
    searchToggles: headerEl.querySelectorAll('[data-header-search-toggle]'),
    headerSearch: headerEl.querySelector('[data-header-search]'),
    primayNavParentListItem: headerEl.querySelectorAll('li.menu-item-has-children'),
    clearSearchBtns: document.querySelectorAll('[data-clear-search]'),
    dropdownBody: headerEl.querySelector('[data-dropdown-body]'),
    scrollBody: headerEl.querySelector('.scroll_body'),
    quickLinks: headerEl.querySelector('[data-quicklinks-body]'),
    resourcesLinks: headerEl.querySelectorAll('.header-resources'),
    dropUpMenus: headerEl.querySelectorAll('.header-drop-up-menu'),
    svgIcons: headerEl.querySelectorAll('.header-selector-svg'),
    gradientOverlay: headerEl.querySelector('.scroll-gradient-overlay')
  };
  var baseUrl = window.location.protocol + '//' + window.location.host;
  var regex = new RegExp(baseUrl + '/\\?s=.+');
  if (regex.test(window.location.href)) {
    // If it does, remove the class "has-transparent-header" from the body element
    document.body.classList.remove('has-transparent-header');
  }

  /**
   * Init Header functions and listeners	 *
   */
  const init = () => {
    window.addEventListener('scroll', throttle(toggleStickyHeader, 100));
    window.addEventListener('scroll', debounce(toggleStickyHeader, 200));
    window.addEventListener('resize', debounce(toggleStickyHeader, 200));

    // Primary Nav toggle Listener
    ui.primaryNavToggle.addEventListener('click', e => {
      togglePrimaryNavigation();
    });

    // Search Drawer toggle listeners
    for (var i = ui.searchToggles.length - 1; i >= 0; i--) {
      ui.searchToggles[i].addEventListener('click', e => {
        toggleSearch();
      });
    }

    // Clear search field button(s) listeners
    for (var i = ui.clearSearchBtns.length - 1; i >= 0; i--) {
      ui.clearSearchBtns[i].addEventListener('click', e => {
        e.preventDefault();
        clearSearchInput(e.target);
      });
    }

    // Sub navigation listeners
    for (let i = 0; i < ui.primayNavParentListItem.length; i++) {
      const subNavBtn = ui.primayNavParentListItem[i].querySelector('button');
      subNavBtn.addEventListener('click', e => {
        e.preventDefault();

        // const { target } = e;
        displaySubNavigation(subNavBtn);
      });
    }
    ;
  };

  /**
   * Gets the header height, and applies that height as padding to Primary Nav and Search Drawer
   * @return null
   */
  const getHeaderHeightApplyPadding = () => {
    let headerHeight = ui.headerEl.offsetHeight;
    ui.primaryNav.style.paddingTop = headerHeight + 'px';
    ui.headerSearch.style.paddingTop = headerHeight + 'px';
  };
  let previousHeight = window.innerHeight;

  // 732
  const fullsizeHeight = ui.headerEl.offsetHeight + ui.dropdownBody.offsetHeight + ui.quickLinks.offsetHeight;
  function adjustDropdownHeight() {
    let windowHeight = window.innerHeight;
    const headerHeight = ui.headerEl.offsetHeight;
    let scrollBodyHeight = ui.scrollBody.offsetHeight;
    let quickLinksHeight = ui.quickLinks.offsetHeight;
    let bodyHeight = scrollBodyHeight + quickLinksHeight;
    let excessHeight = bodyHeight - scrollBodyHeight;
    if (windowHeight > previousHeight) {
      if (ui.headerEl.offsetHeight + bodyHeight < fullsizeHeight) {
        ui.scrollBody.style.height = `${ui.scrollBody.offsetHeight + windowHeight % previousHeight}px`;
      }
    } else {
      if (headerHeight + bodyHeight > windowHeight) {
        ui.scrollBody.style.height = `${windowHeight - headerHeight - excessHeight}px`;
      }
    }
    previousHeight = windowHeight;
  }

  /**
   * Toggles open the Primary Nav drawer (and related toggles, etc)
   *
   * @return null
   */
  const togglePrimaryNavigation = () => {
    getHeaderHeightApplyPadding();

    // Close Search Drawer First
    if (ui.headerSearch.classList.contains('is-open') && !ui.primaryNav.classList.contains('is-open')) {
      toggleSearch();
    }
    if (ui.primaryNav.classList.contains('is-open')) {
      // Close Nav
      ui.body.classList.remove('is-nav-open');
      ui.primaryNavToggle.classList.remove('is-open');
      ui.primaryNavToggle.setAttribute('aria-expanded', 'false');
      ui.primaryNav.classList.remove('is-open');
      ui.primaryNav.classList.add('is-closed');
      setPrimaryTabIndexes();
    } else {
      console.log(ui.quickLinks.offsetHeight);

      // Open Nav
      ui.body.classList.add('is-nav-open');
      ui.primaryNavToggle.classList.add('is-open');
      ui.primaryNavToggle.setAttribute('aria-expanded', 'true');
      ui.primaryNav.classList.remove('is-closed');
      ui.primaryNav.classList.add('is-open');
      setPrimaryTabIndexes();
      setSubTabIndexes();
      setFocusToElement('primary-nav-btn', ui.primaryNav, 'skip-link');
    }
    window.addEventListener('resize', adjustDropdownHeight);
    adjustDropdownHeight();
  };

  /**
   * Toggles the search container open/closed
   * @param  {[type]} target [description]
   * @return {[type]}		[description]
   */
  const toggleSearch = target => {
    getHeaderHeightApplyPadding();

    // Close Primary Nav Drawer First
    if (ui.primaryNav.classList.contains('is-open') && !ui.headerSearch.classList.contains('is-open')) {
      togglePrimaryNavigation();
    }

    // Toggle Search container
    if (ui.headerSearch.classList.contains('is-open')) {
      // Close Search
      ui.body.classList.remove('is-search-open');
      for (var i = ui.searchToggles.length - 1; i >= 0; i--) {
        ui.searchToggles[i].classList.remove('is-open');
        ui.searchToggles[i].setAttribute('aria-expanded', 'false');
      }
      ui.headerSearch.classList.remove('is-open');
      ui.headerSearch.classList.add('is-closed');
    } else {
      // Open Search
      ui.body.classList.add('is-search-open');
      for (var i = ui.searchToggles.length - 1; i >= 0; i--) {
        ui.searchToggles[i].classList.add('is-open');
        ui.searchToggles[i].setAttribute('aria-expanded', 'true');
      }
      ui.headerSearch.classList.remove('is-closed');
      ui.headerSearch.classList.add('is-open');
      setFocusToElement('header-search-open', ui.headerSearch, 'skip-link');
    }
  };

  /**
   * Clear the search input's value
   */
  const clearSearchInput = el => {
    const formEl = el.closest('form'),
      searchInput = formEl.querySelector('input[type="search"]');
    searchInput.value = '';
  };

  /**
   * Toggles the sticky header classes - use within a scroll or resize debounce and/or throttle	 *
   */
  const toggleStickyHeader = () => {
    getHeaderHeightApplyPadding();
    let currentScrollPos = window.pageYOffset,
      headerHeight = ui.headerEl.offsetHeight;
    if (currentScrollPos > headerHeight) {
      // When Sticky
      ui.content.paddingTop = headerHeight + 'px';
      ui.body.classList.add('is-sticky-header');
      ui.headerEl.classList.add('is-sticky');
    } else {
      // Revert to Normal
      ui.content.paddingTop = null;
      ui.body.classList.remove('is-sticky-header');
      ui.headerEl.classList.remove('is-sticky');
    }
  };

  /**
   * Closes the subnav for the elements passed in as well as the 3rd level subnav if one is open
   * @param  Element subNavBtn       		Pass in the toggle '<button>' of the active Submenu
   * @param  Element parentLi       		Pass in the 'parent' <li> of the active Submenu
   * @param  Element subNavContainer      Pass in the <div class="sub-nav-container"> of the active Submenu
   *
   * @return null
   */
  const closeSubNav = (subNavBtn, parentLi, subNavContainer) => {
    // Breakpoint for Horizontal Layout
    // md: 768
    const isHorizontalLayout = window.innerWidth >= 768;
    const tertiaryParentLi = subNavContainer.querySelector('li.is-sub-menu-active');
    if (isHorizontalLayout) {
      // DESKTOP LAYOUT
      if (tertiaryParentLi) {
        const tertiaryContainer = tertiaryParentLi.querySelector('.sub-nav-container'),
          tertiaryBtn = tertiaryParentLi.querySelector('button');

        // Close tertiary nav
        tertiaryParentLi.classList.remove('is-sub-menu-active');
        tertiaryContainer.classList.remove('is-active');
        tertiaryContainer.hidden = true;
        tertiaryContainer.style.display = null;
        tertiaryBtn.setAttribute('aria-expanded', 'false');
        setSubTabIndexes();
      }

      // Close Secondary
      parentLi.classList.remove('is-sub-menu-active');
      subNavContainer.classList.remove('is-active');
      subNavContainer.hidden = true;
      subNavContainer.style.display = null;
      subNavBtn.setAttribute('aria-expanded', 'false');
      setSubTabIndexes();
    } else {
      if (tertiaryParentLi) {
        const tertiaryContainer = tertiaryParentLi.querySelector('.sub-nav-container'),
          tertiaryBtn = tertiaryParentLi.querySelector('button');
        $(tertiaryContainer).slideUp(200, function () {
          tertiaryParentLi.classList.remove('is-sub-menu-active');
          tertiaryContainer.classList.remove('is-active');
          tertiaryContainer.hidden = true;
          tertiaryContainer.style.display = null;
          tertiaryBtn.setAttribute('aria-expanded', 'false');
        });
        setSubTabIndexes();
      }
      setSubTabIndexes();
      $(subNavContainer).slideUp(200, function () {
        parentLi.classList.remove('is-sub-menu-active');
        subNavContainer.classList.remove('is-active');
        subNavContainer.hidden = true;
        subNavContainer.style.display = null;
        subNavBtn.setAttribute('aria-expanded', 'false');
      });
    }
  };

  /**
   * Close open subnavs that are not the the one passed in
   */
  const closeOtherSubNavs = currentContainerToKeep => {
    const parentLi = currentContainerToKeep.parentNode;
    for (let siblingLi of parentLi.parentNode.children) {
      if (siblingLi !== parentLi && siblingLi.classList.contains('is-sub-menu-active')) {
        const siblingBtn = siblingLi.querySelector('button'),
          siblingSubNavContainer = siblingLi.querySelector('.sub-nav-container');
        closeSubNav(siblingBtn, siblingLi, siblingSubNavContainer);
      }
    }
  };
  const openSubNav = (subNavBtn, parentLi, subNavContainer) => {
    setSubTabIndexes();

    // Breakpoint for Horizontal Layout
    // md: 768
    const isHorizontalLayout = window.innerWidth >= 768;
    let headerNavMinHeight = 0;
    if (isHorizontalLayout) {
      headerNavMinHeight = subNavContainer.clientHeight > headerNavMinHeight ? Math.round(subNavContainer.clientHeight) : headerNavMinHeight;
      ui.primaryNavUl.style.minHeight = headerNavMinHeight + 'px';
      parentLi.classList.add('is-sub-menu-active');
      subNavContainer.classList.add('is-active');
      subNavContainer.hidden = false;
      subNavBtn.setAttribute('aria-expanded', 'true');
      setSubTabIndexes();
    } else {
      $(subNavContainer).slideDown(200, function () {
        parentLi.classList.add('is-sub-menu-active');
        subNavContainer.classList.add('is-active');
        subNavContainer.hidden = false;
        subNavBtn.setAttribute('aria-expanded', 'true');
      });
      setSubTabIndexes();
    }
  };
  const openResources = () => {
    ui.resourcesLinks.forEach((resourcesLink, index) => {
      const dropUpMenu = ui.dropUpMenus[index];
      const svgIcon = ui.svgIcons[index];
      const anchorTags = dropUpMenu.querySelectorAll('a');
      const menuBotPosition = 32 + ui.quickLinks.offsetHeight;
      function toggleMenu() {
        const isVisible = dropUpMenu.classList.contains('visible');
        if (isVisible) {
          dropUpMenu.classList.remove('visible', 'flex');
          dropUpMenu.classList.add('hidden');
          dropUpMenu.style.bottom = ''; // Reset bottom style
          dropUpMenu.classList.remove('bottom-important'); // Remove the !important class
          svgIcon.classList.remove('active');
          resourcesLink.classList.remove('active');
          ui.gradientOverlay.classList.remove('hide-gradient');
          resourcesLink.setAttribute('aria-expanded', 'false');
          resourcesLink.focus(); // Return focus to the button
        } else {
          dropUpMenu.classList.add('visible', 'flex');
          dropUpMenu.classList.remove('hidden');
          dropUpMenu.style.bottom = menuBotPosition + 'px'; // Set bottom style directly
          dropUpMenu.classList.add('bottom-important'); // Add the !important class
          svgIcon.classList.add('active');
          resourcesLink.classList.add('active');
          ui.gradientOverlay.classList.add('hide-gradient');
          resourcesLink.setAttribute('aria-expanded', 'true');
          anchorTags[0].focus(); // Move focus to the first link
        }

        // Force reflow to ensure style changes are applied
        window.getComputedStyle(dropUpMenu).bottom;

        // Set tabindex based on updated computed style
        setResourcesTabIndex(dropUpMenu, anchorTags);
      }
      resourcesLink.addEventListener('click', function (e) {
        toggleMenu();
        e.stopPropagation(); // Prevent the click event from propagating to document
      });

      // Close menu when clicking outside of it
      document.addEventListener('click', function (e) {
        if (!dropUpMenu.contains(e.target) && e.target !== resourcesLink) {
          dropUpMenu.classList.remove('visible', 'flex');
          dropUpMenu.classList.add('hidden');
          svgIcon.classList.remove('active');
          resourcesLink.classList.remove('active');
          ui.gradientOverlay.classList.remove('hide-gradient');
          setResourcesTabIndex(dropUpMenu, anchorTags);
          resourcesLink.setAttribute('aria-expanded', 'false');
        }
      });
    });
  };

  /**
   * Toggles the (mobile) 'accordion' navigation up to three levels
   *
   * @param  element subNav Should be a 'div.sub-nav-container' element (SVU_Primary_Nav_Walker Class)
   *
   * @return null
   */
  const displaySubNavigation = subNavBtn => {
    const parentLi = subNavBtn.parentNode,
      subNavContainer = parentLi.querySelector('.sub-nav-container');

    // First close any other open subnavs
    closeOtherSubNavs(subNavContainer);

    // OPEN SUB NAV
    if (!subNavContainer.classList.contains('is-active')) {
      openSubNav(subNavBtn, parentLi, subNavContainer);
      setSubTabIndexes();
    } else {
      closeSubNav(subNavBtn, parentLi, subNavContainer);
      setSubTabIndexes();
    }
  };
  function setSubTabIndexes() {
    const menu = document.getElementById('menu-primary-nav');
    const submenus = menu.querySelectorAll('.sub-nav-container');
    submenus.forEach(submenu => {
      // Check if submenu is hidden
      const isHidden = submenu.getAttribute('hidden') !== null;

      // Set tabindex based on visibility
      Array.from(submenu.querySelectorAll('a, button')).forEach(link => {
        if (isHidden) {
          link.setAttribute('tabindex', '-1');
        } else {
          link.setAttribute('tabindex', '0');
        }
      });
    });
  }
  function setPrimaryTabIndexes() {
    const primaryNav = document.getElementById('primary-nav');
    if (!primaryNav) return; // Exit if primary-nav element is not found

    const isClosed = primaryNav.classList.contains('is-closed');
    primaryNav.querySelectorAll('button').forEach(button => {
      if (isClosed) {
        button.setAttribute('tabindex', '-1');
      } else {
        button.setAttribute('tabindex', '0');
      }
    });
  }
  function setResourcesTabIndex(dropUpMenu, anchorTags) {
    const computedStyle = window.getComputedStyle(dropUpMenu);
    if (computedStyle.display === 'none') {
      anchorTags.forEach(anchor => {
        anchor.setAttribute('tabindex', '-1');
      });
    } else {
      anchorTags.forEach(anchor => {
        anchor.setAttribute('tabindex', '0');
      });
    }
  }
  function setFocusToElement(triggerElementId, targetElement, focusLocationId) {
    const triggerElement = document.getElementById(triggerElementId);
    focusLocationId = '.' + focusLocationId;
    if (triggerElement.getAttribute('aria-expanded') === 'true') {
      const tabbableElements = targetElement.querySelectorAll('button:not([tabindex="-1"]):not([style*="display: none"]), a:not([tabindex="-1"]):not([style*="display: none"]), input:not([tabindex="-1"]):not([style*="display: none"]), select:not([tabindex="-1"]):not([style*="display: none"]), textarea:not([tabindex="-1"]):not([style*="display: none"])');
      const lastTabbableItem = tabbableElements[tabbableElements.length - 1];
      if (lastTabbableItem) {
        lastTabbableItem.addEventListener('keydown', function (event) {
          if (event.key === 'Tab' && !event.shiftKey) {
            const focusLocation = document.querySelector(focusLocationId);
            focusLocation.focus();
          }
        });
      }
    }
  }

  // Initiate
  window.onload = function () {
    init();
    toggleStickyHeader();
    adjustDropdownHeight();
    setSubTabIndexes();
    setPrimaryTabIndexes();
    openResources();
  };
};
export default Header;