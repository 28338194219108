var $ = jQuery;
import 'jquery.marquee';
export const Marquee = el => {
  var timer;
  var prevWindowWidth = window.innerWidth;
  window.onscroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const currentWindowWidth = window.innerWidth;

      // Check if the width has changed significantly
      if (Math.abs(currentWindowWidth - prevWindowWidth) > 10) {
        cloneMarquee('upper');
        cloneMarquee('lower');
        startMarquee();
        prevWindowWidth = currentWindowWidth; // Update the previous width
      }
    }, 100);
  };
  cloneMarquee('upper');
  cloneMarquee('lower');
  startMarquee();
  function cloneMarquee(row) {
    const marquee_row = el.querySelector(`[data-js-marquee-${row}]`);
    const repeater_text = marquee_row.getAttribute('data-js-marquee-text');
    const textWidth = repeater_text.length * parseFloat(getComputedStyle(marquee_row).fontSize);
    const clonesNeeded = Math.ceil(3 * window.innerWidth / textWidth);
    marquee_row.innerHTML = `${repeater_text}&nbsp;`.repeat(clonesNeeded);
  }
  function startMarquee() {
    $(el).find('[data-js-marquee-upper]').marquee({
      duration: 40000,
      gap: 6,
      delayBeforeStart: 0,
      direction: 'right',
      duplicated: true,
      startVisible: true,
      pauseOnHover: true
    });
    $(el).find('[data-js-marquee-lower]').marquee({
      duration: 40000,
      gap: 8,
      delayBeforeStart: 0,
      direction: 'left',
      duplicated: true,
      startVisible: true,
      pauseOnHover: true
    });
  }
};
export default Marquee;