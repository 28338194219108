/**
 * Main Site files to include.
 */
import LaunchBlock from './block-launcher.js';
import ScrollAnimations from './scroll-animations';

/* SITE/TEMPLATE IMPORTS */
import Header from './templates/header.js';
import Footer from './templates/footer.js';

/* BLOCK IMPORTS */
import Hero from '@k-blocks/hero/_script';
import BioSlider from '@k-blocks/bio-slider/_script';
import BioCards from '@k-blocks/bio-cards/_script';
import QuoteSlider from '@k-blocks/large-quote/_script';
import Marquee from '@k-blocks/marquee/_script';
import StaticScroll from '@k-blocks/static-scroll/_script';
import AllAccordion from '@k-blocks/all-accordions/_script';
import Carousel from '@k-blocks/carousel/_script';
import CardGrid from '@k-blocks/card-grid/_script';
import LogoSlider from '@k-blocks/logo-slider/_script';
import VideoHero from '@k-blocks/video-hero/_script';
import CampusMap from '@k-blocks/campus-map/_script';

/* Imports to run anything here in site.js */
import { debounce } from 'lodash';

/* KINETIX BLOCKS
	in order for your '_script' partial for your Block to be launched,
	please do the following:

	1. import your _script
		(e.g import MyBlock from '@k-blocks/my-block/_script;')
	2. add a LaunchBlock call, passing it your imported _script
		and a DOM Query for the Launcher
		(e.g LaunchBlock('.my-block#my-block-id', MyBlock); )
*/
LaunchBlock('.header', Header);
LaunchBlock('[data-js-footer]', Footer);
LaunchBlock('[data-js-hero]', Hero);
LaunchBlock('[data-js-bio-slider-block]', BioSlider);
LaunchBlock('[data-js-bio-cards-block]', BioCards);
LaunchBlock('[data-js-quote-slider]', QuoteSlider);
LaunchBlock('[data-js-dropdown]', AllAccordion);
LaunchBlock('[data-js-marquee]', Marquee);
LaunchBlock('[data-js-static-scroll]', StaticScroll);
LaunchBlock('[data-js-all-accordion]', AllAccordion);
LaunchBlock('[data-js-carousel]', Carousel);
LaunchBlock('[data-js-card-grid]', CardGrid);
LaunchBlock('[data-js-logo-slider]', LogoSlider);
LaunchBlock('[data-js-video-hero]', VideoHero);
LaunchBlock('[data-js-campus-map]', CampusMap);

// Pass vh css variable so we can calculate REAL viewport height
// @URL https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', debounce(() => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}, 500));

// Instantiate any custom Functions/Classes
ScrollAnimations();