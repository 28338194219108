// import Swiper JS
import { Swiper } from 'swiper';
import { Autoplay } from 'swiper/modules';
export const LogoSlider = el => {
  const ui = {
    el,
    swiperEl: el.querySelector('.js-logo-swiper'),
    swiperSlides: el.querySelectorAll('.js-swiper-slide')
  };
  if (ui.swiperSlides.length >= 4) {
    const logoSwiper = new Swiper(ui.swiperEl, {
      slidesPerView: 2,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false
      },
      spaceBetween: 30,
      loop: true,
      speed: 4000,
      allowTouchMove: false,
      centeredSlides: false,
      breakpoints: {
        768: {
          slidesPerView: ui.swiperSlides.length > 6 ? 4 : ui.swiperSlides.length - 2
        },
        1024: {
          slidesPerView: ui.swiperSlides.length > 8 ? 6 : ui.swiperSlides.length - 2
        }
      },
      modules: [Autoplay]
    });
    function setAriaHiddenAttributes() {
      logoSwiper.slides.forEach((slide, index) => {
        const isCurrentSlide = index === logoSwiper.activeIndex;
        slide.setAttribute('aria-hidden', !isCurrentSlide);
      });
    }
    function updateSlider() {
      logoSwiper.update();
    }
    logoSwiper.on('slideChange', () => {
      setAriaHiddenAttributes();
    });
    window.addEventListener('resize', updateSlider);
  }
};
export default LogoSlider;