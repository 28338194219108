import AOS from 'aos';
import { Swiper } from 'swiper';
import { Navigation } from 'swiper/modules';
export const QuoteSlider = el => {
  const ui = {
    el,
    swiperEl: el.querySelector('[data-js-quote-swiper]'),
    nextBtn: el.querySelector('[data-js-quote-next]'),
    prevBtn: el.querySelector('[data-js-quote-prev]'),
    quoteContainers: el.querySelectorAll('[data-js-quote-container]')
  };
  const quoteSwiper = new Swiper(ui.swiperEl, {
    init: true,
    spaceBetween: 40,
    allowTouchMove: true,
    autoHeight: true,
    effect: 'slide',
    speed: 500,
    loop: ui.quoteContainers.length > 1,
    // Enable loop
    lazyLoadingInPrevNext: true,
    navigation: {
      nextEl: ui.nextBtn,
      prevEl: ui.prevBtn
    },
    modules: [Navigation]
  });
  function checkButtonVisibility() {
    if (quoteSwiper.slides.length === 1) {
      const buttonCont = ui.el.querySelector('[data-js-button-container]');
      buttonCont.classList.add('hidden');
      buttonCont.setAttribute('aria-hidden', 'true');
    }
  }
  function setAriaHiddenAttributes() {
    quoteSwiper.slides.forEach((slide, index) => {
      const isCurrentSlide = index === quoteSwiper.activeIndex;
      slide.setAttribute('aria-hidden', !isCurrentSlide);
    });
  }
  function updateSliderLayout() {
    quoteSwiper.update();
  }
  window.addEventListener('resize', updateSliderLayout);
  updateSliderLayout();
  setAriaHiddenAttributes();
  checkButtonVisibility();
  quoteSwiper.on('init', () => {
    AOS.refresh();
  });
  quoteSwiper.on('slideChange', () => {
    setAriaHiddenAttributes();
    AOS.refresh();
  });
  quoteSwiper.on('slideChangeTransitionEnd', () => {
    AOS.refresh();
  });
};
export default QuoteSlider;