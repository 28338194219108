import AOS from 'aos';

/* SCROLL ANIMATION DEFAULTS */
const ScrollAnimations = () => {
  // Assign AOS to window so that 3rd party scripts (Hubspot) can access it in callbacks
  window.AOS = AOS;
  AOS.init({
    offset: 150,
    duration: 350,
    easing: 'ease-in-sine'
  });
};
export default ScrollAnimations;