// Import Swiper and module styles
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
export const Hero = el => {
  const swiperEl = '[data-js-swiper]';
  const ui = {
    el,
    swiper: el.querySelector(swiperEl)
  };
  const {
    jsSlideInterval
  } = ui.swiper.dataset;
  const swipers = document.querySelectorAll(swiperEl);
  const swiperInstances = [];
  swipers.forEach(swiperEl => {
    const swiperInstance = new Swiper(swiperEl, {
      allowTouchMove: true,
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: jsSlideInterval,
        disableOnInteraction: false
      },
      speed: 1500,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      modules: [Autoplay, EffectFade, Pagination]
    });
    swiperInstances.push(swiperInstance);
  });
  function updateAriaHidden(currentSwiper) {
    const slides = currentSwiper.slides;
    slides.forEach((slide, index) => {
      const isActive = index === currentSwiper.realIndex;
      slide.setAttribute('aria-hidden', !isActive);
    });
  }
  swiperInstances.forEach(swiper => {
    updateAriaHidden(swiper);
    swiper.on('slideChange', () => {
      updateAriaHidden(swiper);
    });
  });
};
export default Hero;