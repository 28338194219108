import MicroModal from 'micromodal';
export const Footer = footerEl => {
  const ui = {
    footerEl,
    openModal: footerEl.querySelector('[data-js-footer-modal-open-button]'),
    closeModal: footerEl.querySelector('[data-js-footer-modal-close-button]'),
    openFeedbackModal: footerEl.querySelector('[data-js-footer-feedback-modal-open-button]'),
    closeFeedbackModal: footerEl.querySelector('[data-js-footer-feedback-modal-close-button]')
  };
  if (ui.openModal) {
    ui.openModal.addEventListener('click', () => {
      MicroModal.show('footer-modal', {
        disableScroll: true,
        disableFocus: false,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true
      });
    });
  }
  if (ui.openFeedbackModal) {
    ui.openFeedbackModal.addEventListener('click', () => {
      MicroModal.show('footer-feedback-modal', {
        disableScroll: true,
        disableFocus: false,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true
      });
    });
  }
  if (ui.closeModal) {
    ui.closeModal.addEventListener('click', () => {
      MicroModal.close('footer-modal', {
        disableScroll: true,
        disableFocus: false,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true
      });
    });
  }
  if (ui.closeFeedbackModal) {
    ui.closeFeedbackModal.addEventListener('click', () => {
      MicroModal.close('footer-feedback-modal', {
        disableScroll: true,
        disableFocus: false,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true
      });
    });
  }
};
export default Footer;