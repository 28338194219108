import AOS from 'aos';
var $ = jQuery;
export const AllAccordion = el => {
  $(el).ready(function () {
    // Hide all slide-dropdown divs
    $(el).find('[data-js-category-content]').hide();
    $(el).find('[data-js-category-indicator]').removeClass('active');
    AOS.refresh();

    // Add an event listener to each menu-item div
    $(el).find('[data-js-category-title]').on('click keydown', function (e) {
      if (e.type === 'click' || e.type === 'keydown' && (e.which === 13 || e.which === 32)) {
        e.preventDefault();
        const dropdown = $(this).next('[data-js-category-content]');
        const svg = $(this).find('[data-js-category-indicator]');
        const title = $(this);

        // Check if the dropdown is already visible
        if (dropdown.is(':visible')) {
          // If it is visible, hide it and rotate the SVG back to its original position
          dropdown.slideUp(400, function () {
            AOS.refresh();
          });
          svg.removeClass('active');
          title.attr('aria-expanded', 'false');
        } else {
          // If it is not visible, hide all other dropdowns, show this one, and rotate the SVG 180 degrees
          // Activate the following two lines of code to only allow one element to be opened at a time:
          // $(el).find('[data-js-category-content]').slideUp();
          // $(el).find('[data-js-category-indicator]').removeClass('active');
          dropdown.slideDown(400, function () {
            AOS.refresh();
          });
          svg.addClass('active');
          title.attr('aria-expanded', 'true');
        }
      }
    });
  });
};
export default AllAccordion;