import MicroModal from 'micromodal';
import populateBioModal from '@k-bits/bios/bio-modals';
import { debounce } from 'lodash';
export const BioCards = el => {
  const ui = {
    el,
    bioCards: el.querySelectorAll('.bio-cards__card'),
    modalBtns: el.querySelectorAll('[data-js-bio-modal]')
  };

  /**
   * For each Bio Card, if an AOS anchor data attribute is not present, reset the animation delays
   * to accommodate the onscroll animations for each row of cards, adjusting the delay count
   * every 2, 3, or 4 iterations depending on the number of columns in the responsive layout.
   */
  if (ui.bioCards.length > 0 && ui.bioCards[0].getAttribute('data-aos-delay') && !ui.bioCards[0].getAttribute('data-aos-anchor')) {
    const delayMS = ui.bioCards[1] !== undefined ? ui.bioCards[1].getAttribute('data-aos-delay') : 0;
    document.addEventListener('DOMContentLoaded', debounce(resetAOSdelays, 100));
    window.addEventListener('resize', debounce(resetAOSdelays, 100));
    function resetAOSdelays() {
      let mqMobile = window.matchMedia('(max-width: 767px)').matches;
      let mqTablet = window.matchMedia('(max-width: 1023px)').matches;
      let mqDesktop = window.matchMedia('(min-width: 1024px)').matches;
      let counter = 0;
      for (let i = 0; i < ui.bioCards.length; i++) {
        // Since the first element delay is set to 0 by default,
        // get original delay from the 2nd Bio Card Element
        if (delayMS == undefined) {
          delayMS = ui.bioCards[1].getAttribute('data-aos-delay');
        }

        // Check which media query matches and run a remainder check
        // against the # of columns for that media query
        // to reset the next row's delay sequence starting at 0
        if (mqMobile && i % 2 == 0) {
          counter = 0;
        } else if (mqTablet && i % 3 == 0) {
          counter = 0;
        } else if (mqDesktop && i % 4 == 0) {
          counter = 0;
        }

        // Update the data-aos-delay attribute with the updated delayMS
        ui.bioCards[i].setAttribute('data-aos-delay', delayMS * counter);
        counter++;
      }
    }
  }

  /**
   * For each Bio Card button, initiate MicroModal (required MicroModal and populateBioModal )
   */
  for (var i = ui.modalBtns.length - 1; i >= 0; i--) {
    ui.modalBtns[i].addEventListener('click', e => {
      const modalData = e.currentTarget.getAttribute('data-all-bio-fields');
      initModal(modalData);
    });
    ui.modalBtns[i].addEventListener('keydown', e => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        const modalData = e.currentTarget.getAttribute('data-all-bio-fields');
        initModal(modalData);
      }
    });
  }
  function initModal(modalData) {
    // Micromodal for each Bio;
    MicroModal.show('bio-cards-modal', {
      onShow: modal => populateBioModal(modalData, modal),
      openTrigger: 'data-bio-cards-modal-trigger',
      closeTrigger: 'data-bio-cards-modal-close',
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true
    });
  }
};
export default BioCards;